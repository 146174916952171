.uf_form {
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 45px;
  padding: 35px 20px;
  height: auto;
  width: 100%;
  min-width: 600px;
  max-width: 1000px;
  border-radius: 4px;
  border: 1px solid lightgray;
  box-sizing: border-box;

  .uf_form_row {
    position: relative;
    display: flex;
    column-gap: 20px;
    //min-height: 50px;

    > div .uf_datepicker_width {
      height: auto;
      width: 100%;

      > div {
        height: 40px;
      }
    }
  }

  .uf_form_title {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 5px;
  }

  .uf_textarea {
    position: relative;
    height: 100px !important;
    width: 100%;
    display: block;
    border-radius: 7px;
  }

  .right {
    justify-content: right;
  }

  .center {
    justify-content: center;
  }
}

.uf_form_buttons {
  position: relative;
  display: flex;
  column-gap: 15px;
  padding: 0 15px 10px 0;
  justify-content: center;
}

body
  > div.MuiDialog-root.MuiModal-root.css-zw3mfo-MuiModal-root-MuiDialog-root
  > div.MuiDialog-container.MuiDialog-scrollPaper.css-hz1bth-MuiDialog-container
  > div {
  max-width: unset !important;
}

body
  > div.MuiDialog-root.MuiModal-root.css-126xj0f
  > div.MuiDialog-container.MuiDialog-scrollPaper.css-ekeie0
  > div {
  max-width: unset !important;
}
