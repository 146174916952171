.home_wrapper {
  width: 95%;
  height: 100%;
  display: grid;
  position: relative;
  align-items: center;
  grid-template-rows: auto;
  justify-self: center;
  margin-top: 50px;

  .home_header {
    width: 15%;
    position: relative;
    display: block;
    justify-self: flex-end;
    align-self: center;
  }

  .home_content_wrapper {
    display: grid;
    grid-template-rows: auto auto;
    width: 100%;
    height: 100%;
    position: relative;
    gap: 20px;

    .home_cards_wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 20px;
      width: 100%;
      height: auto;
      position: relative;

      .card {
        width: 100%;
        height: 100%;
        // max-height: 255px;
        min-height: 255px;
        border-radius: 8px;
        border: 2px solid var(--Blue-Primary, #0047ab);
        background: var(--White-Primary, #fff);

        .card_title {
          text-align: center;
          height: 10%;
          border-bottom: 2px solid #0047ab;
        }

        .card_content {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          flex-direction: column;
        }

        .card_button {
          border-radius: 5px;
          border: 2px solid var(--Blue-Primary, #0047ab);
          background: var(--White-Primary, #fff);
        }

        .card_count {
          font-family: Metropolis;
          font-size: 64px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }
    }

    .home_charts_wrapper {
      display: grid;
      justify-content: center;
      align-items: center;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 1900px) and (min-width: 900px) {
  .home_charts_wrapper {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-auto-flow: row !important;
    grid-template-columns: 100% !important;
    place-items: center;
  }

  .home_cards_wrapper {
    display: grid;
    grid-template-rows: repeat(2, 1fr) !important;
    grid-template-columns: repeat(2, 1fr) !important;
    row-gap: 20px !important;
    column-gap: 20px !important;
    width: 100%;
    height: auto;
    position: relative;
    place-items: center;

    .card {
      max-width: 500px;
    }
  }
}
