.profile_card {
  border-radius: 8px;
  background: var(--White-Primary, #fff);
  width: 100%;
  place-self: center;
  margin-bottom: 20px;
  grid-template-rows: 75px auto;
  height: 100%;

  .profile_title {
    font-size: 28px;
    color: #0047ab;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #0047ab;
  }

  .profile_content {
    place-self: center;
    grid-template-rows: 250px auto;

    .profile_image {
      width: 250px;
      height: 250px;
      display: block;
      position: relative;
      place-self: center;
    }
  }
}
