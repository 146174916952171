body {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Calibri;
  font-size: 16px;
  font-weight: normal;
}

html,
#root {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  height: 100%;
  width: 100%;
  font-family: Poppins !important;
  overflow: hidden;

  * {
    font-family: Poppins !important;
    scrollbar-color: #dee2e7 #fff;
    scrollbar-width: thin !important;

    ::-webkit-scrollbar-thumb {
      background-color: #cccccc;
      border: 4.5px solid transparent;
      border-left-width: 2px;
      border-right-width: 2px;
      border-radius: 2px;
      background-clip: content-box;
    }

    ::-webkit-scrollbar {
      background-color: #fff;
      width: 11px;
      overflow: visible;
      height: 15px;
    }
  }

  .uf_loading {
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 17px;
  }

  .uf_layout {
    background-color: #f8fafb;
    grid-template-columns: 320px auto;
    overflow: hidden;
  }

  .menu_layout {
    width: 320px;
    transition: width 1s ease, opacity 1s ease;
    background-color: rgba(30, 30, 30, 0.7);
    overflow: hidden;
    overflow-y: auto;
  }

  .content_layout {
    grid-template-rows: auto;
    width: 100%;
    height: 100%;
    justify-self: center;
    overflow: hidden;
    align-items: center;
  }
}

.patient_wrapper {
  grid-template-columns: 40% 55%;
  justify-content: center;
  height: 95%;
}

@media (max-width: 1900px) and (min-width: 900px) {
  .uf_layout {
    overflow: auto !important;
  }

  html {
    overflow: auto !important;
  }

  .content_layout {
    overflow: auto !important;
  }

  .patient_wrapper {
    grid-auto-flow: row;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: unset !important;
    row-gap: 30px;
    justify-content: center;
    height: 95%;
  }
}
