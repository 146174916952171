.menu_item {
  position: relative;
  display: block;
  width: 280px;
  height: 57px;
  color: #a5a7af !important;
  font-size: 16px;
}

.menu_item_active {
  border-radius: 10px !important;
  background-color: rgba(40, 40, 40, 0.35) !important;
  color: white !important;
}

.css-1domaf0 {
  width: 280px !important;
}

.region_select {
  min-width: 150px;
  label {
    color: #a5a7af !important;
  }

  div {
    border: 1px solid #a5a7af !important;
    min-width: 125px;

    div {
      color: #a5a7af !important;
    }
    svg {
      color: #a5a7af !important;
    }
  }
}
