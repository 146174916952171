@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLcWpXo_CmM6erK5IinBZ-8N8u45WU12y72TEk3aAZtZE);

@media (max-width: 1400px) and (min-width: 900px) {
  .subject_buttons {
    width: 40% !important;
    margin-left: 0 !important;
  }

  .doc-content {
    padding: 60px 20px 60px 20px !important;
  }
}

.MuiSelect-multiple {
  white-space: pre-wrap !important;
}

.subject_buttons {
  width: 45%;
  justify-self: start;
  align-self: center;
  grid-auto-flow: column;
  margin-left: 40px;
}

.archived {
  [id="subject.delovoden_broj"],
  [id="social_worker.first_name"],
  [id="sbj_key_approved_social_worker"],
  [id="sbj_key_approved_psychologist"],
  [id="sbj_key_approved_special_educator_and_rehabilitator"],
  [id="sbj_key_approved_doctor"],
  [id="psychologist.first_name"],
  [id="special_educator_and_rehabilitator.first_name"],
  [id="doctor.first_name"],
  [id="patients.ordinal_number"],
  [id="view_patients_last_subject.number_and_date"] {
    display: none;
  }

  [id="3_5_social_worker_fullname"],
  [id="1_0_patients_ordinal_number"],
  [id="3_5_psychologist_fullname"],
  [id="3_5_special_educator_and_rehabilitator_fullname"],
  [id="1_2_number_and_date_of_subject"],
  [id="3_5_doctor_fullname"] {
    display: block !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.subject_wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: scroll;

  [id="3_5_social_worker_fullname"],
  [id="1_0_patients_ordinal_number"],
  [id="3_5_psychologist_fullname"],
  [id="3_5_special_educator_and_rehabilitator_fullname"],
  [id="3_5_doctor_fullname"],
  [id="1_2_number_and_date_of_subject"] {
    display: none;
  }

  [id="3_1_mislenje_za_funcionlnite_sposobnosti_i_vidot_i_stepenot_na_poprecenost"],
  [id="3_5_razmena_na_informacii"] {
    width: 100%;
  }

  .doc-content {
    max-width: unset !important;
    width: auto !important;
  }

  table {
    width: 100%;
    margin: 0 !important;
    word-break: break-all;
  }

  .subject_tag {
    span {
      padding: 0;
      font-size: unset;
      line-height: normal;
      font: 14px normal;
    }

    span[contenteditable] {
      border: 1px solid rgb(118, 118, 118) !important;
    }

    span[readonly] {
      border: 1px solid rgb(118, 118, 118) !important;
    }
  }

  .tagify__tag > div::before {
    background-color: #e5e5e5;
  }

  .tagify {
    --tag--max-width: 650px;
  }
  .tagify__tag-text {
    white-space: nowrap;
  }

  .tagify__tag__removeBtn {
    display: none;
  }

  .subject_select {
    div {
      border-radius: unset;
      border-width: 2px;
      border-color: -internal-light-dark(
        rgb(118, 118, 118),
        rgb(133, 133, 133)
      );
    }

    div > div {
      padding: 2px 4px;
      background-color: white;
      border-radius: unset;
      font: 14px normal;
    }
  }

  // .sbj_stb {
  //   width: 100%;
  // }

  textarea {
    width: 98%;
    height: 100%;
    font: 14px normal;
  }

  input {
    width: 98%;
    height: 100%;
    font: 14px normal;
  }

  select {
    width: 100%;
    height: 100%;
    font: 14px normal;
  }

  .lst-kix_list_4-1 > li {
    counter-increment: lst-ctn-kix_list_4-1;
  }

  ol.lst-kix_list_7-0 {
    list-style-type: none;
  }

  .lst-kix_list_2-1 > li {
    counter-increment: lst-ctn-kix_list_2-1;
  }

  .lst-kix_list_6-1 > li {
    counter-increment: lst-ctn-kix_list_6-1;
  }

  ol.lst-kix_list_3-1.start {
    counter-reset: lst-ctn-kix_list_3-1 0;
  }

  ol.lst-kix_list_6-6.start {
    counter-reset: lst-ctn-kix_list_6-6 0;
  }

  ol.lst-kix_list_7-4.start {
    counter-reset: lst-ctn-kix_list_7-4 0;
  }

  .lst-kix_list_5-0 > li {
    counter-increment: lst-ctn-kix_list_5-0;
  }

  .lst-kix_list_7-0 > li {
    counter-increment: lst-ctn-kix_list_7-0;
  }

  ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0;
  }

  ol.lst-kix_list_7-5 {
    list-style-type: none;
  }

  ol.lst-kix_list_7-6 {
    list-style-type: none;
  }

  ol.lst-kix_list_7-7 {
    list-style-type: none;
  }

  ol.lst-kix_list_7-8 {
    list-style-type: none;
  }

  ol.lst-kix_list_7-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0;
  }

  ol.lst-kix_list_7-2 {
    list-style-type: none;
  }

  ol.lst-kix_list_7-3 {
    list-style-type: none;
  }

  ol.lst-kix_list_7-4 {
    list-style-type: none;
  }

  ol.lst-kix_list_5-3.start {
    counter-reset: lst-ctn-kix_list_5-3 0;
  }

  .lst-kix_list_2-3 > li {
    counter-increment: lst-ctn-kix_list_2-3;
  }

  .lst-kix_list_4-3 > li {
    counter-increment: lst-ctn-kix_list_4-3;
  }

  ol.lst-kix_list_4-5.start {
    counter-reset: lst-ctn-kix_list_4-5 0;
  }

  .lst-kix_list_1-2 > li {
    counter-increment: lst-ctn-kix_list_1-2;
  }

  ol.lst-kix_list_3-7.start {
    counter-reset: lst-ctn-kix_list_3-7 0;
  }

  .lst-kix_list_5-2 > li {
    counter-increment: lst-ctn-kix_list_5-2;
  }

  .lst-kix_list_3-2 > li {
    counter-increment: lst-ctn-kix_list_3-2;
  }

  .lst-kix_list_7-2 > li {
    counter-increment: lst-ctn-kix_list_7-2;
  }

  // .lst-kix_list_5-0 > li:before {
  //   content: "" counter(lst-ctn-kix_list_5-0, decimal) ". ";
  // }

  ol.lst-kix_list_6-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_6-1 {
    list-style-type: none;
  }

  .lst-kix_list_5-4 > li {
    counter-increment: lst-ctn-kix_list_5-4;
  }

  .lst-kix_list_1-4 > li {
    counter-increment: lst-ctn-kix_list_1-4;
  }

  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0;
  }

  .lst-kix_list_5-3 > li:before {
    content: "" counter(lst-ctn-kix_list_5-3, decimal) ". ";
  }

  .lst-kix_list_5-2 > li:before {
    content: "" counter(lst-ctn-kix_list_5-2, lower-roman) ". ";
  }

  .lst-kix_list_5-1 > li:before {
    content: "" counter(lst-ctn-kix_list_5-1, lower-latin) ". ";
  }

  .lst-kix_list_5-7 > li:before {
    content: "" counter(lst-ctn-kix_list_5-7, lower-latin) ". ";
  }

  .lst-kix_list_5-6 > li:before {
    content: "" counter(lst-ctn-kix_list_5-6, decimal) ". ";
  }

  .lst-kix_list_5-8 > li:before {
    content: "" counter(lst-ctn-kix_list_5-8, lower-roman) ". ";
  }

  ol.lst-kix_list_6-6 {
    list-style-type: none;
  }

  ol.lst-kix_list_6-7 {
    list-style-type: none;
  }

  .lst-kix_list_5-4 > li:before {
    content: "" counter(lst-ctn-kix_list_5-4, lower-latin) ". ";
  }

  ol.lst-kix_list_6-8 {
    list-style-type: none;
  }

  .lst-kix_list_5-5 > li:before {
    content: "" counter(lst-ctn-kix_list_5-5, lower-roman) ". ";
  }

  ol.lst-kix_list_6-2 {
    list-style-type: none;
  }

  ol.lst-kix_list_6-3 {
    list-style-type: none;
  }

  ol.lst-kix_list_6-4 {
    list-style-type: none;
  }

  ol.lst-kix_list_6-5 {
    list-style-type: none;
  }

  ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0;
  }

  .lst-kix_list_6-1 > li:before {
    content: "" counter(lst-ctn-kix_list_6-1, lower-latin) ". ";
  }

  .lst-kix_list_6-3 > li:before {
    content: "" counter(lst-ctn-kix_list_6-3, decimal) ". ";
  }

  .lst-kix_list_6-5 > li {
    counter-increment: lst-ctn-kix_list_6-5;
  }

  .lst-kix_list_6-8 > li {
    counter-increment: lst-ctn-kix_list_6-8;
  }

  .lst-kix_list_6-0 > li:before {
    content: "" counter(lst-ctn-kix_list_6-0, decimal) ". ";
  }

  .lst-kix_list_6-4 > li:before {
    content: "" counter(lst-ctn-kix_list_6-4, lower-latin) ". ";
  }

  .lst-kix_list_3-0 > li {
    counter-increment: lst-ctn-kix_list_3-0;
  }

  ol.lst-kix_list_4-0.start {
    counter-reset: lst-ctn-kix_list_4-0 1;
  }

  .lst-kix_list_3-6 > li {
    counter-increment: lst-ctn-kix_list_3-6;
  }

  li.li-bullet-2:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }

  .lst-kix_list_6-2 > li:before {
    content: "" counter(lst-ctn-kix_list_6-2, lower-roman) ". ";
  }

  .lst-kix_list_2-5 > li {
    counter-increment: lst-ctn-kix_list_2-5;
  }

  .lst-kix_list_2-8 > li {
    counter-increment: lst-ctn-kix_list_2-8;
  }

  ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 0;
  }

  .lst-kix_list_6-8 > li:before {
    content: "" counter(lst-ctn-kix_list_6-8, lower-roman) ". ";
  }

  .lst-kix_list_6-5 > li:before {
    content: "" counter(lst-ctn-kix_list_6-5, lower-roman) ". ";
  }

  .lst-kix_list_6-7 > li:before {
    content: "" counter(lst-ctn-kix_list_6-7, lower-latin) ". ";
  }

  ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0;
  }

  .lst-kix_list_6-6 > li:before {
    content: "" counter(lst-ctn-kix_list_6-6, decimal) ". ";
  }

  ol.lst-kix_list_1-3 {
    list-style-type: none;
  }

  ol.lst-kix_list_1-4 {
    list-style-type: none;
  }

  .lst-kix_list_2-7 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) "."
      counter(lst-ctn-kix_list_2-1, decimal) "."
      counter(lst-ctn-kix_list_2-2, decimal) "."
      counter(lst-ctn-kix_list_2-3, decimal) "."
      counter(lst-ctn-kix_list_2-4, decimal) "."
      counter(lst-ctn-kix_list_2-5, decimal) "."
      counter(lst-ctn-kix_list_2-6, decimal) "."
      counter(lst-ctn-kix_list_2-7, decimal) " ";
  }

  .lst-kix_list_2-7 > li {
    counter-increment: lst-ctn-kix_list_2-7;
  }

  ol.lst-kix_list_1-5 {
    list-style-type: none;
  }

  .lst-kix_list_7-4 > li:before {
    content: "" counter(lst-ctn-kix_list_7-4, lower-latin) ". ";
  }

  .lst-kix_list_7-6 > li:before {
    content: "" counter(lst-ctn-kix_list_7-6, decimal) ". ";
  }

  ol.lst-kix_list_1-6 {
    list-style-type: none;
  }

  ol.lst-kix_list_1-0 {
    list-style-type: none;
  }

  .lst-kix_list_2-5 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) "."
      counter(lst-ctn-kix_list_2-1, decimal) "."
      counter(lst-ctn-kix_list_2-2, decimal) "."
      counter(lst-ctn-kix_list_2-3, decimal) "."
      counter(lst-ctn-kix_list_2-4, decimal) "."
      counter(lst-ctn-kix_list_2-5, decimal) " ";
  }

  ol.lst-kix_list_6-2.start {
    counter-reset: lst-ctn-kix_list_6-2 0;
  }

  ol.lst-kix_list_1-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_1-2 {
    list-style-type: none;
  }

  .lst-kix_list_7-2 > li:before {
    content: "" counter(lst-ctn-kix_list_7-2, lower-roman) ". ";
  }

  .lst-kix_list_7-6 > li {
    counter-increment: lst-ctn-kix_list_7-6;
  }

  ol.lst-kix_list_4-6.start {
    counter-reset: lst-ctn-kix_list_4-6 0;
  }

  ol.lst-kix_list_3-0.start {
    counter-reset: lst-ctn-kix_list_3-0 0;
  }

  .lst-kix_list_5-7 > li {
    counter-increment: lst-ctn-kix_list_5-7;
  }

  .lst-kix_list_7-7 > li {
    counter-increment: lst-ctn-kix_list_7-7;
  }

  .lst-kix_list_7-8 > li:before {
    content: "" counter(lst-ctn-kix_list_7-8, lower-roman) ". ";
  }

  ol.lst-kix_list_4-3.start {
    counter-reset: lst-ctn-kix_list_4-3 0;
  }

  ol.lst-kix_list_1-7 {
    list-style-type: none;
  }

  .lst-kix_list_4-7 > li {
    counter-increment: lst-ctn-kix_list_4-7;
  }

  ol.lst-kix_list_1-8 {
    list-style-type: none;
  }

  .lst-kix_list_7-8 > li {
    counter-increment: lst-ctn-kix_list_7-8;
  }

  ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0;
  }

  li.li-bullet-1:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }

  .lst-kix_list_2-6 > li {
    counter-increment: lst-ctn-kix_list_2-6;
  }

  .lst-kix_list_4-1 > li:before {
    content: "" counter(lst-ctn-kix_list_4-1, lower-latin) ". ";
  }

  ol.lst-kix_list_7-3.start {
    counter-reset: lst-ctn-kix_list_7-3 0;
  }

  .lst-kix_list_4-3 > li:before {
    content: "" counter(lst-ctn-kix_list_4-3, decimal) ". ";
  }

  .lst-kix_list_4-5 > li:before {
    content: "" counter(lst-ctn-kix_list_4-5, lower-roman) ". ";
  }

  ol.lst-kix_list_5-7.start {
    counter-reset: lst-ctn-kix_list_5-7 0;
  }

  .lst-kix_list_1-8 > li {
    counter-increment: lst-ctn-kix_list_1-8;
  }

  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0;
  }

  .lst-kix_list_5-5 > li {
    counter-increment: lst-ctn-kix_list_5-5;
  }

  .lst-kix_list_3-5 > li {
    counter-increment: lst-ctn-kix_list_3-5;
  }

  ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0;
  }

  .lst-kix_list_3-4 > li {
    counter-increment: lst-ctn-kix_list_3-4;
  }

  ol.lst-kix_list_4-4.start {
    counter-reset: lst-ctn-kix_list_4-4 0;
  }

  .lst-kix_list_6-4 > li {
    counter-increment: lst-ctn-kix_list_6-4;
  }

  .lst-kix_list_6-3 > li {
    counter-increment: lst-ctn-kix_list_6-3;
  }

  ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0;
  }

  ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0;
  }

  ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0;
  }

  ol.lst-kix_list_7-6.start {
    counter-reset: lst-ctn-kix_list_7-6 0;
  }

  ol.lst-kix_list_6-1.start {
    counter-reset: lst-ctn-kix_list_6-1 0;
  }

  .lst-kix_list_1-1 > li:before {
    content: "" counter(lst-ctn-kix_list_1-1, lower-latin) ". ";
  }

  .lst-kix_list_1-3 > li:before {
    content: "" counter(lst-ctn-kix_list_1-3, decimal) ". ";
  }

  .lst-kix_list_4-8 > li {
    counter-increment: lst-ctn-kix_list_4-8;
  }

  .lst-kix_list_1-7 > li:before {
    content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". ";
  }

  ol.lst-kix_list_5-8.start {
    counter-reset: lst-ctn-kix_list_5-8 0;
  }

  ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0;
  }

  .lst-kix_list_1-3 > li {
    counter-increment: lst-ctn-kix_list_1-3;
  }

  .lst-kix_list_1-5 > li:before {
    content: "" counter(lst-ctn-kix_list_1-5, lower-roman) ". ";
  }

  .lst-kix_list_5-6 > li {
    counter-increment: lst-ctn-kix_list_5-6;
  }

  ol.lst-kix_list_7-5.start {
    counter-reset: lst-ctn-kix_list_7-5 0;
  }

  .lst-kix_list_2-1 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) "."
      counter(lst-ctn-kix_list_2-1, decimal) " ";
  }

  ol.lst-kix_list_6-0.start {
    counter-reset: lst-ctn-kix_list_6-0 0;
  }

  .lst-kix_list_2-3 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) "."
      counter(lst-ctn-kix_list_2-1, decimal) "."
      counter(lst-ctn-kix_list_2-2, decimal) "."
      counter(lst-ctn-kix_list_2-3, decimal) " ";
  }

  .lst-kix_list_4-2 > li {
    counter-increment: lst-ctn-kix_list_4-2;
  }

  ol.lst-kix_list_3-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_3-2 {
    list-style-type: none;
  }

  .lst-kix_list_3-1 > li {
    counter-increment: lst-ctn-kix_list_3-1;
  }

  ol.lst-kix_list_3-3 {
    list-style-type: none;
  }

  ol.lst-kix_list_3-4.start {
    counter-reset: lst-ctn-kix_list_3-4 0;
  }

  .lst-kix_list_5-1 > li {
    counter-increment: lst-ctn-kix_list_5-1;
  }

  ol.lst-kix_list_3-4 {
    list-style-type: none;
  }

  ol.lst-kix_list_3-0 {
    list-style-type: none;
  }

  .lst-kix_list_1-1 > li {
    counter-increment: lst-ctn-kix_list_1-1;
  }

  .lst-kix_list_7-1 > li {
    counter-increment: lst-ctn-kix_list_7-1;
  }

  ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0;
  }

  .lst-kix_list_3-0 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, upper-roman) ". ";
  }

  ol.lst-kix_list_7-7.start {
    counter-reset: lst-ctn-kix_list_7-7 0;
  }

  .lst-kix_list_3-1 > li:before {
    content: "" counter(lst-ctn-kix_list_3-1, lower-latin) ". ";
  }

  .lst-kix_list_3-2 > li:before {
    content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ". ";
  }

  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0;
  }

  .lst-kix_list_4-0 > li {
    counter-increment: lst-ctn-kix_list_4-0;
  }

  .lst-kix_list_6-0 > li {
    counter-increment: lst-ctn-kix_list_6-0;
  }

  .lst-kix_list_3-5 > li:before {
    content: "" counter(lst-ctn-kix_list_3-5, lower-roman) ". ";
  }

  .lst-kix_list_3-4 > li:before {
    content: "" counter(lst-ctn-kix_list_3-4, lower-latin) ". ";
  }

  .lst-kix_list_3-3 > li:before {
    content: "" counter(lst-ctn-kix_list_3-3, decimal) ". ";
  }

  ol.lst-kix_list_3-5 {
    list-style-type: none;
  }

  ol.lst-kix_list_3-6 {
    list-style-type: none;
  }

  ol.lst-kix_list_3-7 {
    list-style-type: none;
  }

  ol.lst-kix_list_3-8 {
    list-style-type: none;
  }

  .lst-kix_list_3-8 > li:before {
    content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". ";
  }

  .lst-kix_list_2-0 > li {
    counter-increment: lst-ctn-kix_list_2-0;
  }

  .lst-kix_list_3-6 > li:before {
    content: "" counter(lst-ctn-kix_list_3-6, decimal) ". ";
  }

  .lst-kix_list_3-7 > li:before {
    content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". ";
  }

  ol.lst-kix_list_5-0.start {
    counter-reset: lst-ctn-kix_list_5-0 0;
  }

  ol.lst-kix_list_4-2.start {
    counter-reset: lst-ctn-kix_list_4-2 0;
  }

  ol.lst-kix_list_2-2 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-3 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-4 {
    list-style-type: none;
  }

  ol.lst-kix_list_7-2.start {
    counter-reset: lst-ctn-kix_list_7-2 0;
  }

  ol.lst-kix_list_2-5 {
    list-style-type: none;
  }

  .lst-kix_list_4-4 > li {
    counter-increment: lst-ctn-kix_list_4-4;
  }

  ol.lst-kix_list_2-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-1 {
    list-style-type: none;
  }

  .lst-kix_list_4-8 > li:before {
    content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". ";
  }

  ol.lst-kix_list_6-4.start {
    counter-reset: lst-ctn-kix_list_6-4 0;
  }

  .lst-kix_list_4-7 > li:before {
    content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". ";
  }

  ol.lst-kix_list_5-6.start {
    counter-reset: lst-ctn-kix_list_5-6 0;
  }

  ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 0;
  }

  .lst-kix_list_7-3 > li {
    counter-increment: lst-ctn-kix_list_7-3;
  }

  ol.lst-kix_list_4-8.start {
    counter-reset: lst-ctn-kix_list_4-8 0;
  }

  ol.lst-kix_list_3-3.start {
    counter-reset: lst-ctn-kix_list_3-3 0;
  }

  ol.lst-kix_list_2-6 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-7 {
    list-style-type: none;
  }

  ol.lst-kix_list_2-8 {
    list-style-type: none;
  }

  ol.lst-kix_list_7-8.start {
    counter-reset: lst-ctn-kix_list_7-8 0;
  }

  ol.lst-kix_list_7-1.start {
    counter-reset: lst-ctn-kix_list_7-1 0;
  }

  .lst-kix_list_3-3 > li {
    counter-increment: lst-ctn-kix_list_3-3;
  }

  ol.lst-kix_list_6-3.start {
    counter-reset: lst-ctn-kix_list_6-3 0;
  }

  ol.lst-kix_list_5-5.start {
    counter-reset: lst-ctn-kix_list_5-5 0;
  }

  .lst-kix_list_7-0 > li:before {
    content: "" counter(lst-ctn-kix_list_7-0, decimal) ". ";
  }

  .lst-kix_list_2-2 > li {
    counter-increment: lst-ctn-kix_list_2-2;
  }

  ol.lst-kix_list_4-7.start {
    counter-reset: lst-ctn-kix_list_4-7 0;
  }

  .lst-kix_list_6-2 > li {
    counter-increment: lst-ctn-kix_list_6-2;
  }

  ol.lst-kix_list_5-0 {
    list-style-type: none;
  }

  .lst-kix_list_2-6 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) "."
      counter(lst-ctn-kix_list_2-1, decimal) "."
      counter(lst-ctn-kix_list_2-2, decimal) "."
      counter(lst-ctn-kix_list_2-3, decimal) "."
      counter(lst-ctn-kix_list_2-4, decimal) "."
      counter(lst-ctn-kix_list_2-5, decimal) "."
      counter(lst-ctn-kix_list_2-6, decimal) " ";
  }

  .lst-kix_list_3-7 > li {
    counter-increment: lst-ctn-kix_list_3-7;
  }

  ol.lst-kix_list_5-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_5-2 {
    list-style-type: none;
  }

  .lst-kix_list_2-4 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) "."
      counter(lst-ctn-kix_list_2-1, decimal) "."
      counter(lst-ctn-kix_list_2-2, decimal) "."
      counter(lst-ctn-kix_list_2-3, decimal) "."
      counter(lst-ctn-kix_list_2-4, decimal) " ";
  }

  .lst-kix_list_2-8 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) "."
      counter(lst-ctn-kix_list_2-1, decimal) "."
      counter(lst-ctn-kix_list_2-2, decimal) "."
      counter(lst-ctn-kix_list_2-3, decimal) "."
      counter(lst-ctn-kix_list_2-4, decimal) "."
      counter(lst-ctn-kix_list_2-5, decimal) "."
      counter(lst-ctn-kix_list_2-6, decimal) "."
      counter(lst-ctn-kix_list_2-7, decimal) "."
      counter(lst-ctn-kix_list_2-8, decimal) " ";
  }

  .lst-kix_list_7-1 > li:before {
    content: "" counter(lst-ctn-kix_list_7-1, lower-latin) ". ";
  }

  .lst-kix_list_7-5 > li:before {
    content: "" counter(lst-ctn-kix_list_7-5, lower-roman) ". ";
  }

  .lst-kix_list_6-6 > li {
    counter-increment: lst-ctn-kix_list_6-6;
  }

  ol.lst-kix_list_5-4.start {
    counter-reset: lst-ctn-kix_list_5-4 0;
  }

  .lst-kix_list_7-3 > li:before {
    content: "" counter(lst-ctn-kix_list_7-3, decimal) ". ";
  }

  ol.lst-kix_list_5-1.start {
    counter-reset: lst-ctn-kix_list_5-1 0;
  }

  ol.lst-kix_list_5-7 {
    list-style-type: none;
  }

  .lst-kix_list_6-7 > li {
    counter-increment: lst-ctn-kix_list_6-7;
  }

  ol.lst-kix_list_5-8 {
    list-style-type: none;
  }

  ol.lst-kix_list_5-3 {
    list-style-type: none;
  }

  ol.lst-kix_list_5-4 {
    list-style-type: none;
  }

  .lst-kix_list_1-7 > li {
    counter-increment: lst-ctn-kix_list_1-7;
  }

  ol.lst-kix_list_3-8.start {
    counter-reset: lst-ctn-kix_list_3-8 0;
  }

  ol.lst-kix_list_5-5 {
    list-style-type: none;
  }

  ol.lst-kix_list_5-6 {
    list-style-type: none;
  }

  .lst-kix_list_7-7 > li:before {
    content: "" counter(lst-ctn-kix_list_7-7, lower-latin) ". ";
  }

  .lst-kix_list_7-5 > li {
    counter-increment: lst-ctn-kix_list_7-5;
  }

  .lst-kix_list_5-8 > li {
    counter-increment: lst-ctn-kix_list_5-8;
  }

  .lst-kix_list_4-0 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) ". ";
  }

  .lst-kix_list_3-8 > li {
    counter-increment: lst-ctn-kix_list_3-8;
  }

  ol.lst-kix_list_6-8.start {
    counter-reset: lst-ctn-kix_list_6-8 0;
  }

  .lst-kix_list_4-6 > li {
    counter-increment: lst-ctn-kix_list_4-6;
  }

  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0;
  }

  .lst-kix_list_4-4 > li:before {
    content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". ";
  }

  ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0;
  }

  .lst-kix_list_1-5 > li {
    counter-increment: lst-ctn-kix_list_1-5;
  }

  ol.lst-kix_list_6-5.start {
    counter-reset: lst-ctn-kix_list_6-5 0;
  }

  .lst-kix_list_4-2 > li:before {
    content: "" counter(lst-ctn-kix_list_4-2, lower-roman) ". ";
  }

  .lst-kix_list_4-6 > li:before {
    content: "" counter(lst-ctn-kix_list_4-6, decimal) ". ";
  }

  ol.lst-kix_list_7-0.start {
    counter-reset: lst-ctn-kix_list_7-0 0;
  }

  ol.lst-kix_list_4-0 {
    list-style-type: none;
  }

  ol.lst-kix_list_4-1 {
    list-style-type: none;
  }

  ol.lst-kix_list_4-2 {
    list-style-type: none;
  }

  ol.lst-kix_list_4-3 {
    list-style-type: none;
  }

  .lst-kix_list_2-4 > li {
    counter-increment: lst-ctn-kix_list_2-4;
  }

  ol.lst-kix_list_6-7.start {
    counter-reset: lst-ctn-kix_list_6-7 0;
  }

  ol.lst-kix_list_3-6.start {
    counter-reset: lst-ctn-kix_list_3-6 0;
  }

  .lst-kix_list_5-3 > li {
    counter-increment: lst-ctn-kix_list_5-3;
  }

  ol.lst-kix_list_4-8 {
    list-style-type: none;
  }

  .lst-kix_list_7-4 > li {
    counter-increment: lst-ctn-kix_list_7-4;
  }

  // .lst-kix_list_1-0 > li:before {
  //   content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
  // }

  ol.lst-kix_list_4-4 {
    list-style-type: none;
  }

  ol.lst-kix_list_4-5 {
    list-style-type: none;
  }

  // .lst-kix_list_1-2 > li:before {
  //   content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". ";
  // }

  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0;
  }

  ol.lst-kix_list_4-6 {
    list-style-type: none;
  }

  ol.lst-kix_list_4-7 {
    list-style-type: none;
  }

  // .lst-kix_list_1-4 > li:before {
  //   content: "" counter(lst-ctn-kix_list_1-4, lower-latin) ". ";
  // }

  ol.lst-kix_list_3-5.start {
    counter-reset: lst-ctn-kix_list_3-5 0;
  }

  .lst-kix_list_1-0 > li {
    counter-increment: lst-ctn-kix_list_1-0;
  }

  .lst-kix_list_1-6 > li {
    counter-increment: lst-ctn-kix_list_1-6;
  }

  // .lst-kix_list_1-6 > li:before {
  //   content: "" counter(lst-ctn-kix_list_1-6, decimal) ". ";
  // }

  li.li-bullet-0:before {
    margin-left: -36pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 36pt;
  }

  .lst-kix_list_2-0 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) ". ";
  }

  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 1;
  }

  .lst-kix_list_4-5 > li {
    counter-increment: lst-ctn-kix_list_4-5;
  }

  .lst-kix_list_1-8 > li:before {
    content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". ";
  }

  .lst-kix_list_2-2 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) "."
      counter(lst-ctn-kix_list_2-1, decimal) "."
      counter(lst-ctn-kix_list_2-2, decimal) " ";
  }

  ol.lst-kix_list_5-2.start {
    counter-reset: lst-ctn-kix_list_5-2 0;
  }

  ol {
    margin: 0;
    padding: 0;
  }

  table td,
  table th {
    padding: 0;
  }

  .c61 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: bottom;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #f2f2f2;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 503.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c52 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: bottom;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #f2f2f2;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 569.1pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c18 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: bottom;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #b7dde8;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 1.1pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c22 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #b7dde8;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 731.5pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c106 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #943734;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 42.5pt;
    border-top-color: #943734;
    border-bottom-style: solid;
  }

  .c38 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #ffffff;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 477.9pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c51 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: bottom;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #f2f2f2;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 739.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c21 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: bottom;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #f2f2f2;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 1.1pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c5 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #b7dde8;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 63.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c8 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #b7dde8;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 113.4pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c25 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 297.6pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c99 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: bottom;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 739.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c1 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 270pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c24 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 106.3pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c4 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 39.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c63 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 477.9pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c41 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 177.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c87 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 253.5pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c116 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: bottom;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 502.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c88 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 96.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c14 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 91.5pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c90 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: bottom;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 237.1pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c102 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: bottom;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 170.1pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c44 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 138.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c109 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 118.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c23 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 603.6pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c71 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 63.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c47 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 155.9pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c28 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 5.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c16 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 71.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c40 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 45pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c13 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 57pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c49 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 113.4pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c89 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: bottom;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 569.1pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c62 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 85.1pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c68 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 141.8pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c64 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 120.5pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c91 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 666.3pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c37 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 134.7pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c39 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: bottom;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 236pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c86 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 217.5pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c17 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 81pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c57 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 127.9pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c12 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 56.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c97 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 99.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c105 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 184.5pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c11 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 645pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c27 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 56.7pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c33 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 588.2pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c72 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 666.9pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c114 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 616.7pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c78 {
    border-right-style: solid;
    padding: 3.6pt 5.8pt 3.6pt 5.8pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 730pt;
    border-top-color: #000000;
    border-bottom-style: solid;
  }

  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Calibri";
    font-style: normal;
  }

  .c29 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Calibri";
    font-style: normal;
  }

  .c115 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    font-size: 9pt;
    font-family: "Calibri";
    font-style: normal;
  }

  .c104 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    font-size: 14pt;
    font-family: "Calibri";
    font-style: normal;
  }

  .c66 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: right;
  }

  .c2 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c30 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Calibri";
    font-style: normal;
  }

  .c20 {
    padding-top: 0pt;
    padding-bottom: 10pt;
    line-height: 1.1500000000000001;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c84 {
    color: #ffffff;
    text-decoration: none;
    font-size: 11pt;
    font-family: "Calibri";
    font-style: normal;
  }

  .c113 {
    color: #4f81bd;
    text-decoration: none;
    font-size: 14pt;
    font-family: "Calibri";
    font-style: normal;
  }

  .c111 {
    margin-left: -5.4pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }

  .c77 {
    margin-left: -21.3pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }

  .c54 {
    margin-left: -7.1pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }

  .c46 {
    margin-left: -28.7pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }

  .c98 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.1500000000000001;
    text-align: left;
  }

  .c75 {
    background-color: #ffffff;
    max-width: 698pt;
    padding: 56.7pt 72pt 72pt 72pt;
  }

  .c50 {
    margin-left: 234pt;
    padding-left: 18pt;
  }

  .c48 {
    padding: 0;
    margin: 0;
  }

  .c80 {
    margin-left: 18pt;
    padding-left: 0pt;
  }

  .c10 {
    margin-left: 36pt;
    padding-left: 0pt;
  }

  .c112 {
    height: 36pt;
  }

  .c95 {
    height: 20.7pt;
  }

  .c34 {
    vertical-align: baseline;
  }

  .c15 {
    margin-left: 36pt;
  }

  .c42 {
    height: 15.2pt;
  }

  .c6 {
    font-weight: 700;
  }

  .c19 {
    height: 19pt;
  }

  .c9 {
    height: 11.7pt;
  }

  .c93 {
    height: 28.1pt;
  }

  .c74 {
    height: 26.8pt;
  }

  .c32 {
    background-color: #b7dde8;
  }

  .c96 {
    height: 14.2pt;
  }

  .c110 {
    height: 12.6pt;
  }

  .c85 {
    height: 31.7pt;
  }

  .c69 {
    font-size: 12pt;
  }

  .c73 {
    height: 24.6pt;
  }

  .c82 {
    height: 11.4pt;
  }

  .c65 {
    height: 30.4pt;
  }

  .c45 {
    height: 10.8pt;
  }

  .c60 {
    height: 18.1pt;
  }

  .c36 {
    height: 0pt;
  }

  .c26 {
    height: 8.8pt;
  }

  .c103 {
    height: 10.4pt;
  }

  .c70 {
    height: 16.1pt;
  }

  .c94 {
    height: 13.8pt;
  }

  .c3 {
    height: 11pt;
  }

  .c107 {
    height: 43.6pt;
  }

  .c117 {
    height: 29.6pt;
  }

  .c108 {
    height: 23.4pt;
  }

  .c83 {
    height: 14.7pt;
  }

  .c56 {
    height: 7.2pt;
  }

  .c79 {
    height: 21.4pt;
  }

  .c92 {
    height: 14.9pt;
  }

  .c76 {
    background-color: #f2f2f2;
  }

  .c35 {
    height: 28.8pt;
  }

  .c55 {
    height: 20.6pt;
  }

  .c7 {
    height: 19.9pt;
  }

  .c43 {
    height: 8.2pt;
  }

  .c81 {
    background-color: #c6d9f1;
  }

  .c53 {
    height: 22.9pt;
  }

  .c101 {
    height: 37pt;
  }

  .c31 {
    height: 50.7pt;
  }

  .c58 {
    height: 16.9pt;
  }

  .c100 {
    height: 26.5pt;
  }

  .c59 {
    height: 3pt;
  }

  .c67 {
    height: 13.4pt;
  }

  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri";
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri";
  }

  h1 {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.1500000000000001;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
