.uf_content_wrapper {
  position: relative;
  background-color: #fff;
  height: 100%;
  width: 100%;
  overflow: auto;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 10px 0;
  box-sizing: border-box;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;

  .uf_content_upper {
    margin-top: 29px;
    position: relative !important;
    display: flex !important;
    height: auto !important;
    min-height: 10px !important;
    width: 97% !important;
    justify-content: space-between !important;
    align-items: center !important;
    grid-column-start: 1 !important;
    justify-self: center !important;
    grid-row-start: 1 !important;

    .uf_content_title {
      font-size: 26px;
      font-weight: 600;
      color: rgba(34, 42, 96, 0.9);
    }
  }

  .uf_content_additional_tabs {
    position: absolute;
    top: 50px;
    width: 97% !important;
    min-height: 10px !important;
    justify-content: space-between !important;
    align-items: center !important;
    grid-column-start: 1 !important;
    justify-self: center !important;
    grid-row-start: 1 !important;
  }

  .uf_content_main {
    position: relative;
    display: flex !important;
    height: 100%;
    width: 100%;
    margin-top: 10px;
    flex-direction: column;

    .uf_filters {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 25px;
      width: 100%;
      justify-self: center;
      margin-left: 25px;
      margin-top: 15px;
    }
  }

  .uf_content_wrapper_for_tools {
    min-width: 45px;
    width: auto;
    padding: 0;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    min-height: 34px;
    border-radius: 10px;
    border: 1px solid rgba(34, 42, 96, 0.1);
    background: #fbfbfc;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1) inset,
      2px 2px 4px 0px rgba(0, 0, 0, 0.2);
  }
}

.table_isbns {
  border: 1px solid black;

  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
  }
}

.uf_content_title {
  font-size: 26px;
  font-weight: 600;
  color: rgba(34, 42, 96, 0.9);
}
